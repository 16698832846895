import React, { useEffect, useState } from 'react';

interface Props {}

function Download(props: Props) {
  const {} = props;

  const [osDetails, setOsDetails] = useState('');

  useEffect(() => {
    redirectToDownloadApp();
  }, [osDetails]);

  function generateDeviceFingerprint(): string {
    let osDetails: any;
    const { screen, navigator } = window;
    const userAgent = window.navigator.userAgent; // Use type assertion
    // Check for Windows
    console.log(userAgent);

    if (userAgent.indexOf('Windows') !== -1) {
      osDetails = 'Windows';
      setOsDetails(osDetails);
      console.log('Operating System: Windows');
    }
    // Check for macOS
    else if (userAgent.indexOf('Macintosh') !== -1) {
      osDetails = 'macOS';
      setOsDetails(osDetails);
      console.log('Operating System: macOS');
    } else if (userAgent.indexOf('iPhone') !== -1) {
      osDetails = 'macOS';
      setOsDetails(osDetails);
    } else if (userAgent.indexOf('Android') !== -1) {
      osDetails = 'android';
      setOsDetails(osDetails);
    } else {
      osDetails = 'unknown';
      setOsDetails(osDetails);
    }

    const fingerprint = `${userAgent} - ${screen.width}x${screen.height} - ${osDetails}`;
    return fingerprint;
  }

  const redirectToDownloadApp = async () => {
    generateDeviceFingerprint();
    console.log(osDetails);

    if (osDetails == 'android') {
      window.location.href = 'market://details?id=com.callqr';
    } else if (osDetails == 'macOS') {
      window.location.href = 'https://itunes.apple.com/app/';
    }
  };

  return <div>Download</div>;
}

export default Download;
