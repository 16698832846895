import React, { Fragment, useRef, useEffect, useState } from 'react';
import './sendMessage.scss';
import axios from 'axios';
import config from '../../config/config.json';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function SendMessage(props: any) {
  const { messages, senderName, userId , cid, session_id} = props;
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [msgSent, setMsgSent] = useState(false);
  const [msgSentError, setMsgSentError] = useState(false);
  const [progress, setProgress] = useState(10);

  //send message button click
  const handleSendMessage = async () => {
    //console.log('handle send message');
    try {
      setIsLoading(true);
      let currentProgress = progress;
      const interval = setInterval(() => {
        currentProgress += 10;
        if (currentProgress >= 100) {
          clearInterval(interval);
          //setLoading(false);
        }
        setProgress(Math.min(currentProgress, 100));
      }, 1000);
      const response = await sendMessageAPI();
      if (response.status === 200) {
        setProgress(100);
        setIsLoading(false);
        setMsgSent(true);
      }
    } catch (error) {
      setMsgSentError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {}, [inputValue]);

  //send message api call
  const sendMessageAPI = async () => {
    try {
      const response = await axios.post(`${config.basePath}/contactQRGroupAPI`, {
        cid: cid,
        sessionId: session_id,
        userId: userId,
        senderName: senderName,
        message: inputValue,
        data: window.location.href,
        action: "sendMessageV2"
      });
      return response;
    } catch (error) {
      console.error('Axios Error:', error);
      throw error; // Rethrow the error to handle it in the component
    }
  };

  return (
    <Fragment>
      {isLoading ? (
        // <div className="spinner-container">
        //   <div className="loading-spinner"></div>
        // </div>
        <div style={{ width: 145, height: 145,padding:'30px' }}>
          <CircularProgressbar
            value={progress}
            text={`${progress}%`}
            strokeWidth={10}
            styles={buildStyles({
              textSize: '20px',
              pathTransitionDuration: 0.5,
              pathColor: `rgba(62, 152, 199, ${progress / 100})`,
              textColor: '#f88',
              trailColor: '#d6d6d6'
            })}
          />
        </div>
      ) : msgSentError ? (
        <div className="msgSent-container">Message sending failed...</div>
      ) : msgSent ? (
        <div className="msgSent-container">Message Sent...</div>
      ) : (
        <div className="sendMsgToUser">
          <ul style={{ display: 'inline-block', textAlign: 'left' }}>
            {messages.map((msg: any) => {
              return (
                <li key={msg}>
                  <input
                    type="radio"
                    name="option"
                    value={msg}
                    onChange={(e) => {
                      setInputValue(e.target.value);
                    }}
                  />
                  &nbsp;
                  {msg}
                </li>
              );
            })}
            <input
              style={{ borderRadius: '5px', padding: '5px', color: 'black', marginTop: '5px' }}
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              type="text"
              placeholder="Type your message"
            />
          </ul>
          <button className="sendMsgBtn" disabled={inputValue ? false : true} onClick={handleSendMessage}>
            Send Message
          </button>
        </div>
      )}
    </Fragment>
  );
}

export default SendMessage;
