import { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import config from '../../config/config.json';
import 'react-responsive-modal/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './userDetails.scss';
import homeQR from './assets/hme.png';
import lnfQR from './assets/lnf.png';
import bagQR from './assets/bag.png';
import carQR from './assets/car.png';
import hbdQR from './assets/hbd.png';
import myMusic from './assets/myMusic.mp3';
import downloadGApp from './assets/downloadApp_img_GApp_Store.png';
import downloadAppleApp from './assets/downloadApp_img_Apple_App_Store.png';
import { IoMdCall } from 'react-icons/io';
import { FaUser, FaHome } from 'react-icons/fa';
import { MdBloodtype } from 'react-icons/md';
import SendMessage from '../sendMessage/SendMessage';
import { v4 as uuid } from 'uuid';
import { db } from '../../utils/firebase';
import { onValue, ref, update } from 'firebase/database';
import { getAuth, signInWithCustomToken, User } from 'firebase/auth';
import { receiveForegroundMessage } from '../../utils/firebase';

interface CallQrUser {
  uid: string;
  session_id: string;
  phone?: string;
  videoMandate?: boolean;
  tag: string;
  messages: Array<string>;
  name: string;
  info: string;
  address: string;
  blood_group: string;
  genericValues: {
    key: string;
  };
}

interface Session {
  acceptedBy: string;
  callStatus: string;
  callerToken: string;
  deviceFingerprint: string;
  timestamp: EpochTimeStamp;
}

interface UserDisabledOrNotLinked {
  code: string;
  tag: string;
  message: string;
}

const UserDetails = (props: any) => {
  const history = useHistory();
  const { zmClient, setMediaStream, setIsSupportGalleryView, setIsLoading, setIsVideoMandatory } = props;
  //phone number checking code commented
  //const [open, setOpen] = useState(false);
  const [osDetails, setOsDetails] = useState('');
  const [isRejected, setRejected] = useState(false);
  const [isCallNotAnswered, setCallNotAnswered] = useState(false);
  const [isCancelled, setCancelled] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [realTimeSession, setRealTimeSession] = useState<Session | null>(null);
  const [hasJoinedCall, setHasJoinedCall] = useState(false);

  //const [isOnline, setIsOnline] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const checkInternetConnection = async () => {
    // Check if the browser reports online status
    if (navigator.onLine) {
      try {
        // Attempt to make a network request to verify internet connectivity
        const response = await fetchData();
        console.log(response, 'internet resp');

        if (response?.status == 200) {
          setIsOnline(true);
        } else {
          setIsOnline(false);
        }
      } catch (error) {
        // An error occurred, indicating that the internet is not available
        setIsOnline(false);
      }
    } else {
      // Browser reports that it's offline
      setIsOnline(false);
    }
  };

  const createOrCheckUuid = () => {
    const UniqueCId = localStorage.getItem('cid');
    let unique_id;
    if (UniqueCId) {
      unique_id = UniqueCId;
    } else {
      unique_id = uuid();
      localStorage.setItem('cid', unique_id);
    }
    return unique_id;
  };

  useEffect(() => {
    // Initial check when the component mounts
    //checkInternetConnection();

    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    // Event listener to check for changes in online/offline status
    // window.addEventListener('online', checkInternetConnection);
    // window.addEventListener('offline', checkInternetConnection);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    if (isRejected || isCallNotAnswered) {
      history.push('/thankyou', { isRejected, isCallNotAnswered });
    }
    // Cleanup event listeners on component unmount
    return () => {
      // window.removeEventListener('online', checkInternetConnection);
      // window.removeEventListener('offline', checkInternetConnection);
      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);
    };
  }, [isRejected, isCallNotAnswered]);

  function generateDeviceFingerprint(): string {
    let osDetails: any;
    const { screen, navigator } = window;
    const userAgent = window.navigator.userAgent; // Use type assertion
    console.log(userAgent);
    console.log(userAgent.indexOf('Windows'));

    if (userAgent.indexOf('Windows') !== -1) {
      osDetails = 'Windows';
      setOsDetails(osDetails);
      console.log('Operating System: Windows');
    }
    // Check for macOS
    else if (userAgent.indexOf('Macintosh') !== -1) {
      osDetails = 'macOS';
      setOsDetails(osDetails);
      console.log('Operating System: macOS');
    } else if (userAgent.indexOf('iPhone') !== -1) {
      osDetails = 'macOS';
      setOsDetails(osDetails);
    } else if (userAgent.indexOf('Android') !== -1) {
      osDetails = 'android';
      setOsDetails(osDetails);
    } else {
      osDetails = 'unknown';
      setOsDetails(osDetails);
    }

    const fingerprint = `${userAgent} - ${screen.width}x${screen.height} - ${osDetails}`;
    return fingerprint;
  }

  const [user, setUser] = useState<CallQrUser | null>(null);
  const [userDisabledOrNotLinkedRes, setUserDisabledOrNotLinkedRes] = useState<UserDisabledOrNotLinked | null>(null);
  const [isCameraAvailable, setCameraVisibility] = useState(false);
  const [isAudioInpAvailable, setAudioInpVisibility] = useState(false);
  const [isAudioOutAvailable, setAudioOutVisibility] = useState(false);
  const [showAudioVideoErrorMessage, setShowAudioVideoErrorMessage] = useState(false);
  const [IsUserDisabledOrNotLinked, setUserDisabledOrNotLinked] = useState(false);
  const [IsScanQrApiError, setScanQrApiError] = useState(false);
  const [isConnectionInProgress, setConnectionInProgress] = useState(false);
  const [isCancelBtnDisable, setCancelBtnDisable] = useState(false);
  const [callCancellingText, setCallCancellingText] = useState(false);
  const [progress, setProgress] = useState(10);
  const audio = new Audio(myMusic);

  const callInitiateCallAPI = async () => {
    try {
      listenToRealtimeData();
      const response = await axios.post(`${config.basePath}/contactQRGroupAPI`, {
        uid: user?.uid,
        sessionId: user?.session_id,
        cid: createOrCheckUuid(),
        action: "callUserV2"
      });
      return response; // Return the response for handling in the component
    } catch (error) {
      console.error('Axios Error:', error);
      throw error; // Rethrow the error to handle it in the component
    }
  };

  const listenToRealtimeData = () => {
    console.log('Listening to firebase realtime data');
    const databaseRef = ref(
      db,
      `sessions/${window.location.pathname.replace(/\//g, '_').slice(1)}/${user?.session_id}`
    );
    return onValue(databaseRef, (snapshot) => {
      if (snapshot.exists()) {
        const sessionValue = snapshot.val();
        setRealTimeSession(sessionValue);
        console.log('Realtime data updated - ', sessionValue);
        if (sessionValue.callStatus === 'ringing') {
          setIsRinging(true);
        }
        if (sessionValue.callStatus === 'cancelled') {
          audio.pause();
          setCallCancellingText(false);
          setCancelBtnDisable(false);
          setConnectionInProgress(false);
          setCancelled(true);
          history.push('/thankyou');
        }
        if (sessionValue.rejectedBy != null && sessionValue.access != null) {
          console.log(
            'Rejected size : ' +
              Object.keys(sessionValue.rejectedBy).length +
              ' and access size : ' +
              Object.keys(sessionValue.access).length
          );
          if (Object.keys(sessionValue.rejectedBy).length == Object.keys(sessionValue.access).length - 1) {
            console.log('Call rejected .. going to thank you page with rejected message');
            audio.pause();
            setConnectionInProgress(false);
            setRejected(true);
          }
        }
        if (hasJoinedCall) {
          console.log('Call started already, realtime data is late');
        }
        if (!hasJoinedCall && sessionValue.callerToken) {
          if (user?.session_id) {
            setHasJoinedCall(true);
            joinZoomSession(true, user?.session_id, sessionValue.callerToken);
            //setHasJoinedCall(true);
          }
        }
      }
    });
  };

  const joinZoomSession = async (isFromRTDB: boolean, sessionId: string, callerToken: string) => {
    console.log(
      'joining zoom session with sessionId:',
      sessionId,
      'and isFromRTDB:',
      isFromRTDB,
      'and token:',
      callerToken
    );

    if (hasJoinedCall) {
      console.log('Already joined the call');
      return;
    }

    setIsLoading(true); // Assuming you manage a loading state

    try {
      console.log('call initiating');
      await zmClient.join(sessionId, callerToken, 'Guest', '123456');
      const stream = zmClient.getMediaStream();
      console.log('call proceeding', stream);

      setMediaStream(stream);
      setIsSupportGalleryView(stream.isSupportMultipleVideos());

      if (audio) {
        audio.pause();
      }

      setIsLoading(false);

      // Assuming you want to use params for something, otherwise remove it
      const params = {
        videoMandate: user?.videoMandate
      };
      console.log('Navigating to subsession');
      history.push(`/subsession/${user?.videoMandate}`);
    } catch (joinError) {
      console.error('Zoom join error:', joinError);
      history.push('/thankyou');
      if (audio) {
        audio.pause();
      }
      setConnectionInProgress(false);
    }
  };

  // const joinZoomSession = async (isFromRTDB: boolean, sessionId: string, callerToken: string) => {
  //   console.log(
  //     'joining zoom session with sessionId:',
  //     sessionId,
  //     'and isFromRTDB:',
  //     isFromRTDB,
  //     'and token:',
  //     callerToken
  //   );
  //   if (hasJoinedCall) {
  //     return;
  //   }
  //   try {
  //     console.log('call initiating');
  //     await zmClient.join(sessionId, callerToken, 'Guest', '123456');
  //     const stream = zmClient.getMediaStream();
  //     console.log('call proceeding', stream);
  //     setMediaStream(stream);
  //     console.log('setting mediastream as ', stream);
  //     setIsSupportGalleryView(stream.isSupportMultipleVideos());
  //     audio.pause();
  //     setIsLoading(false);
  //     const params = {
  //       videoMandate: user?.videoMandate
  //     };
  //     history.push(`/subsession/${user?.videoMandate}`);
  //   } catch (joinError) {
  //     console.error('Zoom join error:', joinError);
  //     history.push('/thankyou');
  //     setConnectionInProgress(false);
  //     audio.pause();
  //   }
  // };
  const joinSession = async () => {
    try {
      setConnectionInProgress(true);
      audio.play();
      const response = await callInitiateCallAPI();
      console.log('275', response);
      if (response.status === 200) {
        const { sessionId, callerToken } = response.data;
        // if (!hasJoinedCall) {
        //   joinZoomSession(false, sessionId, callerToken);
        //   setHasJoinedCall(true);
        // } else {
        //   console.log('Call started already, callUser api is late');
        // }
        // if (hasJoinedCall) {
        //   console.log('Call started already, callUser api is late');
        // } else {
        //   setHasJoinedCall(true);
        //   joinZoomSession(false, sessionId, callerToken);
        //   //setHasJoinedCall(true);
        // }
      } else {
        // Handle other cases if needed
        audio.pause();
        setConnectionInProgress(false);
      }
    } catch (apiError: any) {
      console.error('API call error:', apiError?.response?.data?.callStatus);
      audio.pause();
      setConnectionInProgress(false);

      if (!['cancelled', 'rejected'].includes(apiError?.response?.data?.callStatus)) {
        const databaseRef = ref(
          db,
          `sessions/${window.location.pathname.replace(/\//g, '_').slice(1)}/${user?.session_id}`
        );
        update(databaseRef, { callStatus: 'timeout' })
          .then((data) => {
            setCallNotAnswered(true);
            //console.log(data);
          })
          .catch((err) => {
            console.log('Error while updating the records', err);
          });
      }
    }
  };

  const checkForCameraAndMic = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    console.log('devices ====>>> ', devices);
    devices.forEach((eachDevices) => {
      if (eachDevices.kind === 'audioinput') {
        setAudioInpVisibility(true);
      }
      if (eachDevices.kind === 'audiooutput') {
        setAudioOutVisibility(true);
      }
      if (eachDevices.kind === 'videoinput') {
        setCameraVisibility(true);
      }
    });
  };

  const fetchData = async () => {
    try {
      let currentProgress = progress;
      const interval = setInterval(() => {
        currentProgress += 10;
        if (currentProgress >= 100) {
          clearInterval(interval);
        }
        setProgress(Math.min(currentProgress, 100));
      }, 1000);
      // axios.post(`${config.basePath}/callUserV2`, {
      //   isDummy: true
      // });
      const response = await axios.post(`${config.basePath}/contactQRGroupAPI`, {
        // const response = await axios.post(`https://run.mocky.io/v3/13f4b91a-2f77-48b3-a792-3047c56f2938`, {
        qrCodeUrl: window.location.href, // Use the complete URL
        deviceFingerprint: generateDeviceFingerprint(),
        cid: createOrCheckUuid(),
        action: "scanQRCodeV2"
      });
      console.log('response status is' + response.status);

      if (response.status === 200) {
        console.log('scanQRV2', response.data.data);
        setProgress(100);
        if (response?.data?.data?.uid) {
          setUser(response.data.data);
          const guestToken: string = response.data.data.guestToken;
          console.log('trying to login with guestToken : ' + guestToken);
          const auth = getAuth();
          await signInWithCustomToken(auth, guestToken);
          // Get the Firebase user object
          const user: User | null = auth.currentUser;
          console.log('loggedin user is : ', user?.uid);

          return response;
          // setIsVideoMandatory(response.data.data.videoMandate);
          // setIsVideoMandatory(true);
        } else {
          setUserDisabledOrNotLinkedRes(response?.data?.data);
          setUserDisabledOrNotLinked(true);
          return response;
        }
      }
    } catch (error) {
      setScanQrApiError(true);
      console.error('Axios Error:', error);
      throw error; // Rethrow the error to handle it in the component
    }
  };

  useEffect(() => {
    fetchData().catch((error) => {
      console.error('Error fetching data:', error);
      // Here, you can handle the error, for example, by updating the UI accordingly
      setScanQrApiError(true); // Assuming you have a state setter for handling errors
    });
    checkForCameraAndMic();
    receiveForegroundMessage();

    return () => {
      console.log('running clean up');
      setAudioInpVisibility(false);
      setAudioOutVisibility(false);
      setCameraVisibility(false);
      setShowAudioVideoErrorMessage(false);
    };
  }, []); // Empty dependency array

  function getLocalStream() {
    let isVideoMandate = false;
    if (user?.videoMandate === true) {
      isVideoMandate = true;
      console.log(user?.videoMandate);

      // setIsVideoMandatory(true);
    } else {
      isVideoMandate = false;
    }
    navigator.mediaDevices
      .getUserMedia({ video: isVideoMandate, audio: true })
      .then((stream) => {
        joinSession();
      })
      .catch((err) => {
        console.error(`error: ${err}`);
        alert(
          `Please provide ${
            isVideoMandate ? 'camera and microphone' : 'microphone'
          } permission from your browser site settings to proceed further`
        );
      });
  }
  const cancelCallAPI = async () => {
    try {
      const response = await axios.post(`${config.basePath}/cancelCallV2`, {
        uid: user?.uid,
        sessionId: user?.session_id
      });
      return response;
    } catch (error) {
      console.error('Axios Error:', error);
      throw error; // Rethrow the error to handle it in the component
    }
  };

  const handleCancelCall = async () => {
    setCallCancellingText(true);
    setCancelBtnDisable(true);
    console.log('handle cancel call');
    try {
      //updating callStatus on cancelling from caller
      const databaseRef = ref(
        db,
        `sessions/${window.location.pathname.replace(/\//g, '_').slice(1)}/${user?.session_id}`
      );
      update(databaseRef, { callStatus: 'cancelled' })
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log('Error while updating the records', err);
        });

      const response = await cancelCallAPI();
      console.log(response);
      if (response.status === 200) {
        setCallCancellingText(false);
        setCancelBtnDisable(false);
        setConnectionInProgress(false);
        setCancelled(true);
        history.push('/thankyou');
        console.log('call Canceled');
      }
    } catch (error) {
      setCallCancellingText(false);
      setCancelBtnDisable(false);
      setConnectionInProgress(false);
      setCancelled(true);
      history.push('/thankyou');
      console.log('cancel call error', error);
    }
  };

  const redirectToDownloadApp = () => {
    generateDeviceFingerprint();
    console.log(osDetails);

    if (osDetails == 'android') {
      window.location.href = 'market://details?id=com.callqr';
    } else if (osDetails == 'macOS') {
      window.location.href = 'https://apps.apple.com/us/app/google-maps/id585027354';
    }
  };
  return (
    <Fragment>
      <div className="container">
        {!user ? (
          <div>
            {isOnline ? (
              <Fragment>
                {IsScanQrApiError ? (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '75vh'
                      }}
                    >
                      <h4 style={{ color: 'white' }}>Something went wrong.</h4>
                      <h5 style={{ color: 'white' }}>Please scan the QR or try again little later.</h5>
                    </div>
                    <div className="directToAppDownloadContainer">
                      <h5 style={{ color: 'white' }}>
                        Thank you for using <span style={{ fontSize: '24px', color: 'red' }}>CallQR</span>
                      </h5>
                      <img
                        onClick={redirectToDownloadApp}
                        src={osDetails == 'android' ? downloadGApp : downloadAppleApp}
                        alt="QR"
                        height={50}
                        width={150}
                      />
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    {IsUserDisabledOrNotLinked ? (
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '75vh'
                          }}
                        >
                          <h4 style={{ color: 'white', whiteSpace: 'pre-wrap' }}>
                            {userDisabledOrNotLinkedRes?.message}
                          </h4>
                        </div>
                        <div className="directToAppDownloadContainer">
                          <h5 style={{ color: 'white' }}>
                            Thank you for using <span style={{ fontSize: '24px', color: 'red' }}>CallQR</span>
                          </h5>
                          <img
                            onClick={redirectToDownloadApp}
                            src={osDetails == 'android' ? downloadGApp : downloadAppleApp}
                            alt="QR"
                            height={50}
                            width={150}
                          />
                        </div>
                      </div>
                    ) : (
                      <div style={{ width: 145, height: 145, padding: '30px' }}>
                        <CircularProgressbar
                          value={progress}
                          text={`${progress}%`}
                          strokeWidth={10}
                          styles={buildStyles({
                            textSize: '20px',
                            pathTransitionDuration: 0.5,
                            pathColor: `rgba(62, 152, 199, ${progress / 100})`,
                            textColor: '#f88',
                            trailColor: '#d6d6d6'
                          })}
                        />
                      </div>
                    )}
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '75vh'
                  }}
                >
                  <button onClick={checkInternetConnection} className="callBtn">
                    Retry
                  </button>
                </div>
                <div className="directToAppDownloadContainer">
                  <h5 style={{ color: 'white' }}>
                    Thank you for using <span style={{ fontSize: '24px', color: 'red' }}>CallQR</span>
                  </h5>
                  <img
                    onClick={redirectToDownloadApp}
                    src={osDetails == 'android' ? downloadGApp : downloadAppleApp}
                    alt="QR"
                    height={50}
                    width={150}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <Fragment>
            {isConnectionInProgress ? (
              <div>
                {isOnline ? (
                  <div className="callConnectionInProgress">
                    {isRinging ? (
                      <div>Ringing...</div>
                    ) : callCancellingText ? (
                      <div>Cancelling...</div>
                    ) : (
                      <div>Connecting...</div>
                    )}
                    <button disabled={isCancelBtnDisable} className="cancelCallBtn" onClick={handleCancelCall}>
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '75vh'
                      }}
                    >
                      <button onClick={checkInternetConnection} className="callBtn">
                        Retry
                      </button>
                    </div>
                    <div className="directToAppDownloadContainer">
                      <h5 style={{ color: 'white' }}>
                        Thank you for using <span style={{ fontSize: '24px', color: 'red' }}>CallQR</span>
                      </h5>
                      <img
                        onClick={redirectToDownloadApp}
                        src={osDetails == 'android' ? downloadGApp : downloadAppleApp}
                        alt="QR"
                        height={50}
                        width={150}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {isOnline ? (
                  <div>
                    <div className="userDetails">
                      {localStorage.getItem('cid')}
                      <img
                        src={
                          user.tag === 'hme'
                            ? homeQR
                            : user.tag === 'car'
                            ? carQR
                            : user.tag === 'bag'
                            ? bagQR
                            : user.tag === 'lnf'
                            ? lnfQR
                            : hbdQR
                        }
                        alt="QR"
                        height={100}
                        width={100}
                      />
                      <button className="callBtn" onClick={getLocalStream}>
                        <IoMdCall style={{ marginRight: '10px' }} size={24} />
                        Call
                      </button>
                      <div className="userDetailsInfo">
                        {user?.info && (
                          <div className="eachUserDetail">
                            <FaUser style={{ marginRight: '15px' }} size={24} />
                            <h4 style={{ color: 'white' }}>Info :</h4>
                            <h5 style={{ color: 'white' }}>{user.info}</h5>
                          </div>
                        )}
                        {user?.name && (
                          <div className="eachUserDetail">
                            <FaUser style={{ marginRight: '15px' }} size={24} />
                            <h4 style={{ color: 'white' }}>Name :</h4>
                            <h5 style={{ color: 'white' }}>{user.name}</h5>
                          </div>
                        )}
                        {user?.address && (
                          <div className="eachUserDetail">
                            <FaHome style={{ marginRight: '15px' }} size={24} />
                            <h4 style={{ color: 'white' }}>Address :</h4>
                            <h5 style={{ color: 'white' }}>{user.address}</h5>
                          </div>
                        )}
                        {user?.phone && (
                          <div className="eachUserDetail">
                            <IoMdCall style={{ marginRight: '15px' }} size={24} />
                            <h4 style={{ color: 'white' }}>Phone Number :</h4>
                            <h5 style={{ color: 'white' }}>{user.phone}</h5>
                          </div>
                        )}
                        {user?.blood_group && (
                          <div className="eachUserDetail">
                            <MdBloodtype style={{ marginRight: '15px' }} size={24} />
                            <h4 style={{ color: 'white' }}>Blood Group :</h4>
                            <h5 style={{ color: 'white' }}>{user.blood_group}</h5>
                          </div>
                        )}
                        {user?.genericValues && (
                          <div className="genericValues">
                            <ul>
                              {Object.keys(user.genericValues).map((key: string) => {
                                return (
                                  <li key={key}>
                                    <h4 style={{ color: 'white', width: 'auto', textAlign: 'left' }}>{key} :</h4>
                                    <h5 style={{ color: 'white' }}>
                                      {user.genericValues[key as keyof typeof user.genericValues]}
                                    </h5>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                      {user?.messages.length > 0 && (
                        <SendMessage
                          messages={user?.messages}
                          senderName={user?.name}
                          userId={user?.uid}
                          cid={localStorage.getItem('cid')}
                          session_id={user?.session_id}
                        />
                      )}
                    </div>
                    <div className="directToAppDownloadContainer">
                      <h5 style={{ color: 'white' }}>
                        Thank you for using <span style={{ fontSize: '24px', color: 'red' }}>CallQR</span>
                      </h5>
                      <img
                        onClick={redirectToDownloadApp}
                        src={osDetails == 'android' ? downloadGApp : downloadAppleApp}
                        alt="QR"
                        height={50}
                        width={150}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '75vh'
                      }}
                    >
                      <button onClick={checkInternetConnection} className="callBtn">
                        Retry
                      </button>
                    </div>
                    <div className="directToAppDownloadContainer">
                      <h5 style={{ color: 'white' }}>
                        Thank you for using <span style={{ fontSize: '24px', color: 'red' }}>CallQR</span>
                      </h5>
                      <img
                        onClick={redirectToDownloadApp}
                        src={osDetails == 'android' ? downloadGApp : downloadAppleApp}
                        alt="QR"
                        height={50}
                        width={150}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default UserDetails;
