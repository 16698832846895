import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';

function ErrorPage() {
    const history = useHistory();

    const [isOnline, setIsOnline] = useState(true);

    const redirectToErrorage = () => {
      history.push('/notfound');  
    }

    useEffect(() => {
        const checkInternetConnection = async () => {
          // Check if the browser reports online status
          if (navigator.onLine) {
            try {
              // Attempt to make a network request to verify internet connectivity
              const response = await fetch('https://run.mocky.io/v3/13f4b91a-2f77-48b3-a792-3047c56f2938');
              console.log(response,'internet resp');
              
              if (response.ok) {
                setIsOnline(true);
                
              } else {
                setIsOnline(false);
                redirectToErrorage()
              }
            } catch (error) {
              // An error occurred, indicating that the internet is not available
              setIsOnline(false);
              redirectToErrorage()
    
            }
          } else {
            // Browser reports that it's offline
            setIsOnline(false);
            redirectToErrorage()
          }
        };
    
        // Initial check when the component mounts
        checkInternetConnection();
    
        // Event listener to check for changes in online/offline status
        window.addEventListener('online', checkInternetConnection);
        window.addEventListener('offline', checkInternetConnection);
    
        // Cleanup event listeners on component unmount
        return () => {
          window.removeEventListener('online', checkInternetConnection);
          window.removeEventListener('offline', checkInternetConnection);
        };
      }, []);

  return (
    <div className='container'>
        <button>
            Retry
        </button>
    </div>
  )
}

export default ErrorPage